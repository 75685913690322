<template>
    <div>

        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button></p>
            </div>
        </div>
        <el-tabs class="done" type="border-card">
            <el-tab-pane label="商品信息">
                <el-form ref="soForm" :model="dataSource">
                    <el-form-item>
                        <el-col :span="2" class="form-title">商品编码：</el-col>
                        <el-col :span="5">
                            <el-form-item>
                                <span>{{dataSource.ProductCode}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2" class="form-title">商品名称：</el-col>
                        <el-col :span="5">
                            <el-form-item>
                                <span>{{dataSource.ProductName}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2" class="form-title">外部编码：</el-col>
                        <el-col :span="5">
                            <el-form-item>
                                <span>{{dataSource.OuterID}}</span>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                    <el-form-item>
                        <el-col :span="2" class="form-title">主单位：</el-col>
                        <el-col :span="5">
                            <el-form-item>
                                <span>{{dataSource.ProductUnit}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2" class="form-title">商品条码：</el-col>
                        <el-col :span="5">
                            <el-form-item>
                                <span>{{dataSource.ProductBarCode}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2" class="form-title">库存类型：</el-col>
                        <el-col :span="5">
                            <el-form-item>
                                <span>{{dataSource.DisplayInventoryType}}</span>
                            </el-form-item>
                        </el-col>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
        <el-tabs class="done" type="border-card">
            <el-tab-pane label="库存批次详情">
                <el-table :data="dataSource.StockBatchInfoList.Result" border highlight-current-row>
                    <el-table-column prop="ProductBatchNo" label="生产批次">
                        <template slot-scope="scope">
                            <span v-model="scope.row.ProductBatchNo">{{scope.row.ProductBatchNo}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="ProductDate" label="生产日期">
                        <template slot-scope="scope">
                            <span v-model="scope.row.DisplayProductDate">{{scope.row.DisplayProductDate}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="DisplayNormalNum" label="在库">
                        <template slot-scope="scope">
                            <span v-model="scope.row.DisplayNormalNum">{{scope.row.DisplayNormalNum}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="h-table-list">
                    <div class="page">
                        <el-pagination @size-change="onSizeChangeBatch"
                                       @current-change="onPageChangeBatch"
                                       :current-page="queryParamBatch.pageIndex"
                                       :page-sizes="pageSizeArrayBatch"
                                       :page-size="queryParamBatch.pageSize"
                                       layout="total, sizes, prev, pager, next, jumper"
                                       :total="dataSource.StockBatchInfoList.TotalCount">
                        </el-pagination>
                        <el-button type="text" class="btn-refurbish" v-on:click="onRefreshBatch">刷新</el-button>
                    </div>
                </div>
            </el-tab-pane>
            <br />
            <el-tab-pane label="库存变化详情">
                <fixed-list :dataSource="dataSource.StockChangeInfoList" @onDataSourceChange="onDataSourceChange" @onPageChange="onPageChange" :queryParam="queryParam">
                    <p slot="elList">
                        <el-table ref="changeStockListTable" :data="dataSource.StockChangeInfoList.Result" border highlight-current-row>
                            <el-table-column v-for="(col,index,count)  in dataSource.StockChangeInfoList.ColDefs.BodyFieldParams"
                                             :key="index"
                                             :prop="col.FieldName"
                                             :label="col.DisplayName"
                                             :render-header="bindFilter(queryParam,col,ignoreField)"
                                             :fixed="index==0&&(!config||!config.isDetailDisplay)"
                                             :min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
                                             v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay))">
                                <template slot-scope="scope">
                                    <span>  {{ scope.row[col.FieldName] }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </p>
                </fixed-list>
            </el-tab-pane>


        </el-tabs>

    </div>
</template>
<script>
    export default {
        data() {
            return {
                ignoreField: ["DisplayTime", "DisplayTotalNum", "DisplayNormalNum", "DisplayFreezeNum"],
                multipleSelection: [],
                queryParamBatch: {
                    pageIndex: 1,
                    pageSize: 10,
                    Params: {
                    }
                },
                queryParam: {
                    pageIndex: 1,
                    pageSize: 10,
                    Params: {

                    }
                },
                showCount: 1
            }
        },
        props: {
            config: {
                isDetailDisplay: false
            },
            pageSizeArrayBatch: {
                type: Array,
                default: function () {
                    return [10, 20, 30, 50, 100];
                }
            },
            dataSource: {

            }
        },
        mounted() {
            this.Event.$on("clearStockBatchForm", () => this.resetForm);
            var _this = this;
            this.Event.$on("reloadPageList", () => this.reloadPageList());
            this.reloadPageList();
        },
        watch: {
            dataSource: {
                handler: function (curVal, oldVal) {
                    //此处解决一个BUG，第一次进入编辑界面时，库存变化列表的总数量不加载，第一次进入执行刷新操作
                    if (this.showCount == 1) {
                        this.initialize();
                        this.showCount++;
                    }
                    this.isDisabled = true;
                },
                deep: true
            }
        },
        methods: {
            reloadPageList: function () {
                this.initialize();
            },
            initialize() {
                this.onPageChange(this.queryParam);
            },
            onEditPageChange(param) {
                this.loadDateBatch();
                this.initialize();
            },
            onEditDataSourceChange(ds) {
                var _this = this;

                _this.dataSource = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource = ds;

                });
            },
            onPageChange(param) {
                var _this = this;
                this.queryParam = param;
                console.log("重新加载库存变化列表：");
                console.log(this.dataSource);
                console.log(this.dataSource.warehouseid);
                this.queryParam.Params.ProductCode = { fieldName: 'ProductCode', fieldValue: this.dataSource.ProductCode };
                this.queryParam.Params.InventoryType = { fieldName: 'InventoryType', fieldValue: this.dataSource.InventoryType };
                this.queryParam.Params.warehouseid = { fieldName: 'warehouseid', fieldValue: this.dataSource.warehouseid };
                this.$ajax.query("omsapi/stock/stockinfo/searchsummarystockchangeinfo", "post", this.queryParam, data => {
                    console.log("onPageChange");
                    if (_this.dataSource.StockChangeInfoList != null) {
                        _this.dataSource.StockChangeInfoList.Result = data.Result;
                    }
                });
            },
            onDataSourceChange(ds) {
                var _this = this;

                _this.dataSource.StockChangeInfoList = {
                    ColDefs: {
                        BodyFieldParams: []
                    },
                    Result: [],
                    TotalCount: 0
                };
                _this.$nextTick(function () {
                    _this.dataSource.StockChangeInfoList = ds;

                });
            },
            resetForm() {
                !this.isPassValidate && this.$refs['_editstockInfoForm'].resetFields();//清空表单
            },
            onRefreshBatch() {
                this.loadDateBatch();
            },
            onSizeChangeBatch(val) {
                this.queryParamBatch.pageSize = val;
                this.loadDateBatch();
            },
            onPageChangeBatch(val) {
                this.queryParamBatch.pageIndex = val;
                this.loadDateBatch();
            },
            loadDateBatch() {
                var _this = this;
                this.queryParamBatch.Params.ProductCode = { fieldName: 'ProductCode', fieldValue: this.dataSource.ProductCode };
                this.queryParamBatch.Params.InventoryType = { fieldName: 'InventoryType', fieldValue: this.dataSource.InventoryType };
                this.queryParamBatch.Params.warehouseid = { fieldName: 'warehouseid', fieldValue: this.dataSource.warehouseid };

                this.$ajax.query("omsapi/stock/stockinfo/searchsummarystockbatchinfo", "post", this.queryParamBatch, data => {
                    _this.dataSource.StockBatchInfoList.Result = data.Result;
                });
            }
        }
    }
</script>